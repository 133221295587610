import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import AboutUsHeader from "components/GoT/AboutUsHeader.js";
import FooterAboutUs from "components/GoT/FooterAboutUs.js";

function AboutUs() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("about-us");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("about-us");
    };
  });
  return (
    <>
      <MultiDropdownNavbar />
      <AboutUsHeader />
      <div className="main">
        <div className="section">
          <Container>
            <h3 className="title-uppercase">We support local businesses.</h3>
            <p>
              Collaboratively administrate empowered markets via plug-and-play
              networks. Dynamically procrastinate B2C users after installed base
              benefits. Dramatically visualize customer directed convergence
              without revolutionary ROI.
            </p>
            <p>
              Efficiently unleash cross-media information without cross-media
              value. Quickly maximize timely deliverables for real-time schemas.
              Dramatically maintain clicks-and-mortar solutions without
              functional solutions.
            </p>
            <h3 className="title-uppercase">
              We
              <i className="fa fa-heart heart mr-3 ml-1" />
              what we do.
            </h3>
            <p>
              Completely synergize resource taxing relationships via premier
              niche markets. Professionally cultivate one-to-one customer
              service with robust ideas. Dynamically innovate resource-leveling
              customer service for state of the art customer service.
            </p>
            {/* ********* TEAM 4 ********* */}
            <div
              className="team-4 section-image"
              style={{
                backgroundImage:
                  "url(" + require("assets/img/GoT_Social_Media/GoT-SocialMedia-01.png") + ")",
                  backgroundPosition: "top center"
              }}
            >
              <Container>
                <Row>
                  <Col className="ml-auto mr-auto text-center" md="8">
                    <h2 className="title">Head Bitches In Charge</h2>
                    <h5 className="description">
                      This is the paragraph where you can write more details about
                      your team. Keep you user engaged by providing meaningful
                      information.
                    </h5>
                    <br />
                  </Col>
                </Row>
                <Row>
                  <Col md="4">
                    <Card className="card-profile">
                      <CardBody>
                        <div className="card-avatar">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/josh.jpg")}
                            />
                            <CardTitle tag="h4">Eric Thomson</CardTitle>
                          </a>
                        </div>
                        <p className="card-description text-center">
                          I love beer.
                        </p>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button
                          className="btn-just-icon mr-1"
                          color="linkedin"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        <Button
                          className="btn-just-icon mr-1"
                          color="dribbble"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                        <Button
                          className="btn-just-icon"
                          color="instagram"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="card-profile">
                      <CardBody>
                        <div className="card-avatar">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/Littell_2.jpg")}
                            />
                            <CardTitle tag="h4">Sophia West</CardTitle>
                          </a>
                        </div>
                        <p className="card-description text-center">
                          I love beer and board games.
                        </p>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button
                          className="btn-just-icon mr-1"
                          color="linkedin"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-linkedin" />
                        </Button>
                        <Button
                          className="btn-just-icon mr-1"
                          color="dribbble"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-dribbble" />
                        </Button>
                        <Button
                          className="btn-just-icon"
                          color="pinterest"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-pinterest" />
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                  <Col md="4">
                    <Card className="card-profile">
                      <CardBody>
                        <div className="card-avatar">
                          <a href="#pablo" onClick={e => e.preventDefault()}>
                            <img
                              alt="..."
                              src={require("assets/img/seth.jpg")}
                            />
                            <CardTitle tag="h4">Lucas Andrew</CardTitle>
                          </a>
                        </div>
                        <p className="card-description text-center">
                          I love board games.
                        </p>
                      </CardBody>
                      <CardFooter className="text-center">
                        <Button
                          className="btn-just-icon mr-1"
                          color="youtube"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-youtube" />
                        </Button>
                        <Button
                          className="btn-just-icon mr-1"
                          color="twitter"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-twitter" />
                        </Button>
                        <Button
                          className="btn-just-icon"
                          color="instagram"
                          href="#pablo"
                          onClick={e => e.preventDefault()}
                        >
                          <i className="fa fa-instagram" />
                        </Button>
                      </CardFooter>
                    </Card>
                  </Col>
                </Row>
              </Container>
            </div>
            {/* ********* END TEAM 4 ********* */}
            <h3 className="more-info">Need more information?</h3>
            <Row className="coloured-cards">
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="blue"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">Best cards</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Blue Card
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        What all of these have in common is that they're pulling
                        information out of the app or the service and making it
                        relevant to the moment.
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="green"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">Best cards</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Green Card
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        What all of these have in common is that they're pulling
                        information out of the app or the service and making it
                        relevant to the moment.
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
              <Col md="4" sm="6">
                <div className="card-big-shadow">
                  <Card
                    className="card-just-text"
                    data-background="color"
                    data-color="yellow"
                    data-radius="none"
                  >
                    <CardBody>
                      <h6 className="card-category">Best cards</h6>
                      <CardTitle tag="h4">
                        <a href="#pablo" onClick={e => e.preventDefault()}>
                          Yellow Card
                        </a>
                      </CardTitle>
                      <p className="card-description">
                        What all of these have in common is that they're pulling
                        information out of the app or the service and making it
                        relevant to the moment.
                      </p>
                    </CardBody>
                  </Card>
                  {/* end card */}
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterAboutUs />
    </>
  );
}

export default AboutUs;
