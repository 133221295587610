import React from "react";

// reactstrap components
import { Container } from "reactstrap";
import {BrowserView, MobileView} from 'react-device-detect';

// core components

function CatalogueHeader() {
  return (
    <>
    <BrowserView>
      <div className="catalogue-header">
        <div className="filter" />
      </div>
    </BrowserView>
    <MobileView>
      <div className="catalogue-header catalogue-mobile">
        <div className="filter" />
      </div>
    </MobileView>
    </>
  );
}

export default CatalogueHeader;
