import React from "react";

// reactstrap components
import { Badge, Button, Card, Media, Container, Row, Col } from "reactstrap";

// core components
import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import BlogPostHeader from "components/Headers/BlogPostHeader.js";
import FooterWhite from "components/GoT/FooterWhite.js";

let jsonResponse = require('../../games.json');

function findGame(arr, propValue) {
  for (var i=0; i < arr.length; i++)
    if (arr[i]["image"] === String(propValue))
      return arr[i];
}

function GameDetails(props) {
  var data = jsonResponse.catalogue;
  const {text, match: {params}} = props;
  const {title} = params;

  const game = findGame(data, title);

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("blog-post");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("blog-post");
    };
  });
  return (
    <>
      <MultiDropdownNavbar background="catalogue-nav" />
      <br />
      <BlogPostHeader background={game.image} title={game.title}/>
      <div className="wrapper">
        <div className="main">
          <div className="section section-white">
            <Container>
              <Row>
                <Col className="ml-auto mr-auto" md="10">
                  <div className="text-center">
                      <h3 className="title">
                        Players: {game.player_count[1] ? game.player_count[0] + "-" + game.player_count[1] : game.player_count[0]}
                      </h3>
                      <h3 className="title">
                        Playtime: {game.time}
                      </h3>
                      <h3 className="title">
                        Walkthrough and Ruleset:
                      </h3>
                      <a href={game.url}>{game.url}</a>
                  </div>
                </Col>
                <Col className="ml-auto mr-auto" md="8">
                  <div className="article-content">
                    <h4>{game.title}</h4>
                    <p>
                      {game.description}
                    </p>
                  </div>
                  <br />
                </Col>
              </Row>
            </Container>
          </div>
        </div>
      </div>
      <FooterWhite />
    </>
  );
}

export default GameDetails;
