/*!

=========================================================
* Paper Kit PRO React - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2019 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Redirect, Switch } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/css/custom.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
import "assets/css/custom.css";
// pages
//import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import AboutUs from "components/GoT/AboutUs.js";
import LandingPage from "components/GoT/LandingPage.js";
import Catalogue from "components/GoT/Catalogue.js";
import CalendarPage from "components/GoT/CalendarPage.js";
import GameDetails from "components/GoT/GameDetails.js";
// others

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Route path="/about-us" render={props => <AboutUs {...props} />} />
      <Route path="/catalogue" render={props => <Catalogue {...props} />} />
      <Route path="/game/:title" render={props => <GameDetails {...props} />} />
      <Route path="/calendar" render={props => <CalendarPage {...props} />} />
      <Route
        path="/"
        render={props => <LandingPage {...props} />}
      />
      <Redirect to="/" />
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
