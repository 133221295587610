import React from "react";
import Calendar from "@ericz1803/react-google-calendar";
import { css } from "@emotion/react";
import {BrowserView, MobileView} from 'react-device-detect';

import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";

const API_KEY = "AIzaSyA2-Hnu2Z7NNxEzfTVQxs7cSI6pqscRmA0";
let calendars = [
  {calendarId: "gamesontaplouisville@gmail.com"}
];
let styles = {
  //you can use object styles (no import required)
  calendar: css`
    border: 3px solid #B58618ff;
    color: black;
    `,
  day: css`
    border: 1px solid #B58618ff;
    `,

  //you can also use emotion's string styles
  today: css`
   /* highlight today by making the text red and giving it a red border */
    color: red;
    border: 3px solid #7F4839ff;
  `,
  tooltip: css`
   /* highlight today by making the text red and giving it a red border */
    opacity: 1;
    overflow-y: auto;
    overflow-x: hidden;
    max-height: 30rem;
  `
}

function CalendarPage() {
  return (
    <>
    <BrowserView>
    <MultiDropdownNavbar background="landing-nav" />
    <div className="CalendarHeader" />
    <div className="Calendar">
      <Calendar apiKey={API_KEY} calendars={calendars} styles={styles}/>
    </div>
    </BrowserView>
    <MobileView>
    <MultiDropdownNavbar background="" />
    <div className="MobileHeader CalendarHeader" />
    <div className="Calendar">
      <Calendar apiKey={API_KEY} calendars={calendars} styles={styles}/>
    </div>
    </MobileView>
    </>
  )
}

export default CalendarPage;
