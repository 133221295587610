/*eslint-disable*/
import React, { useState, useEffect } from "react";
import axios from "axios";
import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
//import { jsonResponse } from '../../gameList';
let jsonResponse = require('../../games.json');

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardImg,
  CardTitle,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import CatalogueHeader from "components/GoT/CatalogueHeader.js";
import FooterWhite from "components/GoT/FooterWhite.js";
import SearchBar from "components/GoT/Search.jsx";
import Fuse from "fuse.js";

function Catalogue() {

  var jsonData = jsonResponse.catalogue.sort((a, b) => (a.title > b.title) ? 1 : (a.title < b.title) ? -1 : 0);

  const [activePill, setActivePill] = React.useState("1");
  const [catalogue, setCatalogue] = React.useState(jsonData);
  const [sortType, setSortType] = React.useState("title");
  const [data, setData] = useState(catalogue);

  const searchData = (pattern) => {
    if (!pattern) {
      setData(catalogue);
      return;
    }
    const fuse = new Fuse(catalogue, {
      keys: ["title", "tags"],
    });
    const result = fuse.search(pattern);
    const matches = [];
    if (!result.length) {
      setData([]);
    } else {
      result.forEach(({item}) => {
      matches.push(item);
    });
      setData(matches);
    }
  }


  useEffect(() => {
    const sortArray = type => {
      var sorted;
      if(type === "title") {
        sorted = [...data].sort((a, b) => (a.title > b.title) ? 1 : (a.title < b.title) ? -1 : 0);
      } else if (type === "player_count_small") {
        sorted = [...data].sort((a, b) => a.player_count[0] - b.player_count[0]);
      } else if (type === "player_count_large") {
        sorted = [...data].sort((a, b) => b.player_count[1] - a.player_count[1]);
      } else if (type === "short_playtimes") {
        sorted = [...data].sort((a, b) => a.time - b.time);
      } else if (type === "long_playtimes") {
        sorted = [...data].sort((a, b) => b.time - a.time);
      }
      setData(sorted);
    };

    sortArray(sortType);
  }, [sortType]);

  return (
    <>
      <MultiDropdownNavbar background="catalogue-nav"/>
      <CatalogueHeader />
      <div className="section-project cd-section" id="projects">
        <div className="projects-1 catalogue">
          <div className="content-center">
            <Container className="text-center">
              <h2 className="title white-text">Our Collection</h2>
              <h5 className="white-text description">
                Here you can find the games we regularly bring to our events. We are constantly expanding our library, so be sure to check back often!
              </h5>
            </Container>
          </div>
          <Container style={{ maxWidth: "none" }}>
            <Row>
              <SearchBar
                placeholder="Search"
                onChange={(e) => searchData(e.target.value)}
              />
            </Row>
            <Row>
              <div className="project-pills">
                <Nav className="nav-pills-danger" pills>
                  <NavItem>
                    <NavLink
                      className={activePill === "1" ? "active" : ""}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill("1");
                        setSortType("title");
                      }}
                    >
                      Alphabetical
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "2" ? "active" : ""}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill("2");
                        setSortType("player_count_small");
                      }}
                    >
                      Small Group
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "3" ? "active" : ""}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill("3");
                        setSortType("player_count_large")
                      }}
                    >
                      Large Group
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "4" ? "active" : ""}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill("4");
                        setSortType("short_playtimes");
                      }}
                    >
                      Short Playtimes
                    </NavLink>
                  </NavItem>
                  <NavItem>
                    <NavLink
                      className={activePill === "5" ? "active" : ""}
                      href="#pablo"
                      onClick={e => {
                        e.preventDefault();
                        setActivePill("5");
                        setSortType("long_playtimes");
                      }}
                    >
                      Long Playtimes
                    </NavLink>
                  </NavItem>
                </Nav>
                {/* Pill panes */}
                <TabContent activeTab={"pill-" + activePill}>
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                  <TabPane tabId={"pill-" + activePill} />
                </TabContent>
              </div>
            </Row>
            <div className="gameList">
            {data.map((game, i) =>
              <Card className="gameCard"
              key={i}
              data-background="image"
              style={{
                backgroundImage:
                  "url(games/" +
                  `${game.image}` +
                  ".jpg)"
              }}>
              <CardBody>
              <a href={"/game/"+game.image} >
                <CardTitle className="gameList" tag="h3">{game.title}</CardTitle>
              </a>
              <p className="card-description text-bg">
                {game.description.length > 100 ? game.description.substring(0,97) + "..." : game.description}
                <br />
                <br />
                Play time: {game.time}
                <br />
                Players: {game.player_count[1] ? game.player_count[0] + '-' + game.player_count[1] : game.player_count[0]}
              </p>
              <br />
              <Button
                className="btn-round"
                color="danger"
                href="#pablo"
                onClick={e => e.preventDefault()}
              >
                <i className="fa fa-book mr-1" />
                More Info
              </Button>
              </CardBody>
              </Card>)}
              </div>
            <div className="space-top" />
          </Container>
        </div>
        {/* ********* END PROJECTS 1 ********* */}
      </div>
      <FooterWhite />
    </>
  );
}

export default Catalogue;
