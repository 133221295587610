import React from "react";

// reactstrap components
import { Button, Col } from "reactstrap";

// core components

function BlogPostHeader(props) {
  let pageHeader = React.createRef();

  return (
    <>
      <div
        className="game-header"
        data-background="image"
        style={{
          backgroundImage:
          "url(../games/" +
          `${props.background}` +
          ".jpg)"
        }}
      >
        <div className="filter" />
        <div className="content-center game-text-background">
          <div className="motto">
            <Col className="ml-auto mr-auto" md="6">
            <div className="text-background">
            <h1 className="title-uppercase text-center white-text game-title">{props.title}</h1>
            </div>
          </Col>
            <br />
          </div>
        </div>
      </div>
    </>
  );
}

export default BlogPostHeader;
