import React from "react";

// reactstrap components
import { Button, Container } from "reactstrap";
import {BrowserView, MobileView} from 'react-device-detect';

// core components

function LandingPageHeader() {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });

  return (
    <>
      <BrowserView>
      <div
        className="landing-header"
        ref={pageHeader}
      >
        <div className="filter" />
      </div>
      </BrowserView>
      <MobileView>
        <div
          className="mobile-landing"
          ref={pageHeader}
        >
          <div className="filter" />
        </div>
      </MobileView>
    </>
  );
}

export default LandingPageHeader;
