import React from "react";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import MultiDropdownNavbar from "components/Navbars/MultiDropdownNavbar.js";
import LandingPageHeader from "components/GoT/LandingPageHeader.js";
import FooterWhite from "components/GoT/FooterWhite.js";

function LandingPage() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("landing-page");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("landing-page");
    };
  });
  return (
    <>
      <MultiDropdownNavbar background="landing-nav" />
      <LandingPageHeader />
      <div className="wrapper">
        <div className="section text-center landing-section background-1"
        style={{
          backgroundImage:
            "url(" + require("assets/img/GoT_hiwire_cropped.jpg") + ")",
            backgroundPosition: "center"
        }}>
          <Container className="text-bg">
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <div className="text-background">
                <h2 className="title white-text">It's Game Time</h2>
                <h5 className="white-text description">
                  We are Games on Tap, a casual meetup group centered around having a good time while
                  supporting local businesses. We travel to different locations throughout the month,
                  but no matter where we go we can always guarantee good games and good beer.
                </h5>
                </div>
                <br />
              </Col>
            </Row>
            <br />
            <br />
            <Row>
              <Col md="4" md-offset="2">
                <div className="info text-background">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-map-big" />
                  </div>
                  <div className="description">
                    <h4 className="title white-text">Fun Games</h4>
                    <p className="description white-text">
                      We have a collection of more than 75
                      games to satisfy any gamer's needs
                    </p>
                    <Button
                      className="btn-link"
                      color="danger"
                      href="/catalogue"
                    >
                      See more
                    </Button>
                  </div>
                </div>
              </Col>
              <Col md="4" md-offset="2">
                <div className="info text-background">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-chat-33" />
                  </div>
                  <div className="description">
                    <h4 className="info-title white-text">Fun People</h4>
                    <p className="description white-text">
                      Maybe you're not feeling up to a board game,
                      but still feel the itch to be social. We're
                      here for that too!
                    </p>
                  </div>
                </div>
              </Col>
              <Col md="4" md-offset="2">
                <div className="info text-background">
                  <div className="icon icon-info">
                    <i className="nc-icon nc-satisfied" />
                  </div>
                  <div className="description">
                    <h4 className="info-title white-text">Good Beer</h4>
                    <p className="description white-text">
                      All of our gaming locations serve a
                      variety of delicious beers. Regardless
                      of your preference, we can find one for
                      you!
                    </p>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        <div className="section section-dark text-center landing-section">
          <Container>
            <h2 className="title">Let's talk about us</h2>
            <Row>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/josh.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Josh Jackson</CardTitle>
                        <h6 className="card-category text-muted">
                          Social Media/Website Development
                        </h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Craft beer, Programming, and Overwatch enthusiest
                    </p>
                  </CardBody>
                  {/*<CardFooter className="text-center">
                    <Button
                      className="btn-link btn-just-icon mr-1"
                      color="twitter"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-link btn-just-icon mr-1"
                      color="dribbble"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-dribbble" />
                    </Button>
                    <Button
                      className="btn-link btn-just-icon"
                      color="linkedin"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>*/}
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <div className="card-avatar">
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <img
                        alt="..."
                        src={require("assets/img/Littell_2.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <a href="#pablo" onClick={e => e.preventDefault()}>
                      <div className="author">
                        <CardTitle tag="h4">Andrew Littell</CardTitle>
                        <h6 className="card-category text-muted">Event Coordinator</h6>
                      </div>
                    </a>
                    <p className="card-description text-center">
                      Board game enthusiast that daylights as a software engineer
                    </p>
                  </CardBody>
                  {/*<CardFooter className="text-center">
                    <Button
                      className="btn-link btn-just-icon mr-1"
                      color="twitter"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-link btn-just-icon mr-1"
                      color="dribbble"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-dribbble" />
                    </Button>
                    <Button
                      className="btn-link btn-just-icon"
                      color="linkedin"
                      href="#pablo"
                      onClick={e => e.preventDefault()}
                    >
                      <i className="fa fa-linkedin" />
                    </Button>
                  </CardFooter>*/}
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
        {/*<div className="section landing-section">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="8">
                <h2 className="text-center">Keep in touch?</h2>
                <Form className="contact-form">
                  <Row>
                    <Col md="6">
                      <label>Name</label>
                      <Input placeholder="Name" />
                    </Col>
                    <Col md="6">
                      <label>Email</label>
                      <Input placeholder="Email" />
                    </Col>
                  </Row>
                  <label>Message</label>
                  <Input
                    placeholder="Tell us your thoughts and feelings..."
                    type="textarea"
                    rows="4"
                  />
                  <Row>
                    <Col className="offset-md-4" md="4">
                      <Button className="btn-fill" color="danger" size="lg">
                        Send Message
                      </Button>
                    </Col>
                  </Row>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>*/}
      </div>
      <FooterWhite />
    </>
  );
}

export default LandingPage;
